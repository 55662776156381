/**
 * Track Metric
 * @author Nicolas Venne
 * 
 * @copyright Klipfolio, All rights reserved
 */
 import React from 'react'
 import PropTypes from 'prop-types'
 import styled from 'styled-components'
 import Heading from '@particles/heading'
 import { parseMarginOrPadding } from '@helpers'
 import Text from '@particles/text'
 import { useStaticQuery, graphql } from 'gatsby'
 import Button from '@atoms/button'
 import Layout from '@organisms/layout'
 import {media} from '@breakpoints'
 import dashImageLarge from '@src/images/cta_dash_image_large.svg'
 import { Theme } from '@styles/theme'
import Link from '@atoms/link'
 
 
 const StyledHeading = styled(Heading)`
   font-weight: 500;
   font-size: 2.5rem;
 `
 
 
 const StyledTracking = styled.div`
   min-width: 900px;
   overflow: hidden;
   display: grid;
   grid-template-columns: 460px 357px;
   background: #2A2F3C;
   box-shadow: 0px 2px 12px 4px rgba(0, 0, 0, 0.08);
   border-radius: 20px;
   padding: 2rem;
   column-gap: 1.5rem;
   row-gap: 1rem;
   margin: ${({margin}) => parseMarginOrPadding(margin)};
 
   ${media.phablet`
     grid-template-columns: 1fr;
   `}
 
   ${media.desktop_medium`
     grid-template-columns: 1fr;
     .visual {
       display: none;
     }
     min-height: auto;
     min-width: unset;
     padding: 2rem;
   `}
 
 `
 
 const DashboardSample = styled.div`
   position: relative;
   img {
     position: absolute;
     left: 2rem;
     align-self: flex-end;
   }
 `
 
 const StyledPMButton = styled(Button)`
   border-radius: 0.5rem;
   background: #75E1B2;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 8px 12px;
   width: 210px;
   height: 44px;
   border-radius: 32px;
   color: ${Theme.palette.primary[500]};
 `
 
 const StyledMetricTemplateContainer = styled.div`
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   column-gap: 1rem;
   row-gap: 0.8125rem;
   align-content: space-between;
   overflow-x: auto;
   ${media.desktop`
     margin-bottom: 0;
   `}
   ${'' /* grid-template-columns: ${props => props.count <= 2 ? "1fr 1fr" : "1fr 1fr 1fr"};
   ${media.phablet`
     grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
   `}
   grid-area: template;
   column-gap: 0.75rem;
   row-gap: 0.75rem; */}
 
 `
 
 
 const TrackMetric = ({  globalMetric, children, ...rest }) => {
   const { site } = useStaticQuery(
     graphql`
       query {
         site {
           siteMetadata {
             links {
               powermetrics
               startTrial
             }
           }
         }
       }
     `
   )
   return (
     <StyledTracking {...rest} singleRow={children.length <= 1}>
       <Layout.Column className={'text'}>
         <StyledHeading h3 margin={'B1'} color={"#FFFFFF"}>
           Start tracking your <b>{globalMetric.name}</b> data
         </StyledHeading>
         {children.length >= 1 ? 
           <>
             <Text color={"100"} margin={"B1"} lineHeight={1.6}>Use <Link style={{color: 'white', fontWeight: '700'}} href="https://www.klipfolio.com/powermetrics">Klipfolio PowerMetrics</Link>, our free analytics tool, to monitor your data. Choose one of the following available services to start tracking your <b>{globalMetric.name}</b> instantly.</Text>
             <StyledMetricTemplateContainer count={children.length}>
               {children}
             </StyledMetricTemplateContainer>
           </>
           :
           <>
             <Text color={"100"} margin={"B1"}>Use <Link style={{color: 'white', fontWeight: '700'}} href="https://www.klipfolio.com/powermetrics">Klipfolio PowerMetrics</Link>, our free analytics tool, to monitor your data.</Text>
             <StyledPMButton href={site.siteMetadata.links.startTrial} target="_blank">
                 Get PowerMetrics Free
               </StyledPMButton>
           </>
         }
       </Layout.Column>
       <Layout.Column className="visual">
         <DashboardSample>
          <img src={dashImageLarge} alt="Klipfolio dashboard image"/>
         </DashboardSample>
       </Layout.Column>
       
     </StyledTracking>
   )
 }
 
 TrackMetric.propTypes = {
   children: PropTypes.oneOfType([
     PropTypes.arrayOf(PropTypes.node),
     PropTypes.node
   ]),
   className: PropTypes.string,
   globalMetric: PropTypes.object
 }
 
 export default TrackMetric
 