import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: none;
  color: white;
  display: flex;
  gap: 0.5rem;
  font-weight: 600;
  transition: 0.2s background;
  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`

export const CopyButton = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text)
    setIsCopied(!isCopied)
  }, [text, isCopied, setIsCopied])

  return (
    <StyledButton onClick={handleCopy}>
      {isCopied ? `Copied` : `Copy`}
      <svg
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {isCopied ? (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.403 5.207h9.147a2.293 2.293 0 0 1 2.287 2.287v9.147a2.293 2.293 0 0 1-2.287 2.286H7.403a2.293 2.293 0 0 1-2.287-2.286V7.494a2.293 2.293 0 0 1 2.287-2.287ZM3.45 1.075h9.147a2.293 2.293 0 0 1 2.287 2.287v.947h-7.48a3.194 3.194 0 0 0-3.185 3.185v7.3H3.45a2.293 2.293 0 0 1-2.287-2.287V3.36A2.293 2.293 0 0 1 3.45 1.073v.002Z"
            fill="#fff"
          />
        ) : (
          <path
            d="M7.47 4.388h6.835v-.966c0-.488-.2-.931-.522-1.253a1.77 1.77 0 0 0-1.253-.522H3.422c-.487 0-.931.2-1.253.522a1.77 1.77 0 0 0-.522 1.253v9.108c0 .487.2.931.522 1.253a1.77 1.77 0 0 0 1.253.522h.966V7.47c0-.848.347-1.618.904-2.177a3.07 3.07 0 0 1 2.178-.905Zm8.143 0h.965c.849 0 1.619.346 2.178.904.558.558.904 1.33.904 2.178v9.108c0 .848-.346 1.618-.904 2.177a3.072 3.072 0 0 1-2.178.905H7.47a3.076 3.076 0 0 1-2.177-.905 3.07 3.07 0 0 1-.905-2.177v-.966h-.965a3.076 3.076 0 0 1-2.178-.905 3.074 3.074 0 0 1-.904-2.177V3.422c0-.848.347-1.619.904-2.177A3.075 3.075 0 0 1 3.423.34h9.108c.848 0 1.618.347 2.177.905.559.558.905 1.328.905 2.177v.966Zm.965 1.307H7.47c-.487 0-.93.2-1.253.522a1.77 1.77 0 0 0-.522 1.253v9.107c0 .488.2.932.522 1.254a1.77 1.77 0 0 0 1.253.521h9.108c.488 0 .931-.2 1.253-.521a1.77 1.77 0 0 0 .522-1.254V7.47c0-.488-.2-.932-.522-1.253a1.77 1.77 0 0 0-1.253-.522Z"
            fill="#fff"
          />
        )}
      </svg>
    </StyledButton>
  )
}

CopyButton.propTypes = {
  text: PropTypes.string
}
