import { yamlify } from './json-yaml'

function snakeCase(text) {
  return text ? text.replace(/(?<!\s)-(?!\s|$)|[, ]/g, '_').toLowerCase() : null
}

function simpleMetric(name) {
  if (!name) {
    return null
  }

  const snake = snakeCase(name)
  const metric = {
    name: snake,
    label: name,
    type: 'simple',
    type_params: {
      measure: `${snake}_placeholder # Substitute your own measure here.`
    }
  }

  return metric
}

function processFormula(input) {
  if (!input) {
    return null
  }

  const sumRegex = /\b(Sum|Count)(?=\()/g // Remove unnecessary aggregations
  const output = input.replace(sumRegex, '')

  const symbolRegex = /\s*([+*/]|(?<=\s)x(?=\s)|(?<=\s)-(?=\s))\s*/i
  const split = output.split(symbolRegex).filter(Boolean) // Splits at formula symbols

  const cleaned = []
  const metrics = []
  const alphaRegex = /[a-zA-Z]/g

  split.forEach((variable) => {
    const brackets = variable.includes('(') && variable.includes(')')
    const snake = snakeCase(variable)

    if (brackets) {
      const inFormula = snake.replace(/^\((.*?)\)$/, '$1') // Formula segment
      cleaned.push(inFormula) // Push bracketless
    } else {
      cleaned.push(snake) // Push with brackets
    }

    if (alphaRegex.test(variable)) {
      const clean = variable.replace(/\(|\)/g, '') // Just text, for the list
      metrics.push(clean)
    }
  })

  const formula = cleaned.join(' ')

  return {
    expression: formula,
    metrics: metrics
  }
}

export function getDbtShell(metric) {
  let type = 'simple'

  const formula =
    metric.formulas?.length > 0
      ? processFormula(metric.formulas[0].formula)
      : null

  if (!formula) {
    return null
  }

  if (formula?.expression) {
    if (formula.metrics.length > 2) {
      type = 'derived'
    } else {
      if (formula.metrics.length !== 1) {
        if (formula.expression.includes('/')) {
          type = 'ratio'
        } else {
          type = 'derived'
        }
      }
    }
  }

  const name = snakeCase(metric.name)
  const description = metric.description.replace(/(\r\n|\n|\r)/gm, '') // Remove new lines, etc

  let yaml = {
    name: name,
    description: description,
    type: type,
    label: metric.name
  }

  if (type === 'simple') {
    yaml = simpleMetric(name)
  } else if (type === 'derived') {
    yaml.type_params = {
      expr: formula.expression,
      metrics: formula.metrics.map((name) => snakeCase(name))
    }
  } else {
    yaml.type_params = {
      numerator: snakeCase(formula.metrics[0]),
      denominator: snakeCase(formula.metrics[1])
    }
  }

  const relatedMetrics = formula.metrics.map((name) => simpleMetric(name))

  const dbt = {
    metrics: [...relatedMetrics, yaml]
  }

  return yamlify(dbt)
}
