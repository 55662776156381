import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '@icons'
import { media } from '@breakpoints'
import { parseMarginOrPadding } from '@styles/helper'

const ScrollGridContainer = styled.div`
  position: relative;
  height: ${props => props.height ? `${props.height}px` : '418px'};
  margin: ${(props) => parseMarginOrPadding(props.margin, '1rem 0 0 0')};
`

const StyledScrollContainer = styled.div`
  overflow-x: scroll;
  width: calc(100vw - 1rem);
  position: absolute;
  padding: 1rem 0;
  transform: translateX(calc(calc(max(1280px, 100vw)/2 - 624px) * -1));
  
  ${
    media.desktop_medium_up`
      width: calc(100vw);
      transform: translateX(calc(calc(max(1280px, 100vw)/2 - 616px) * -1));
    `
  }

  ${
    media.desktop`
      margin-left: -3rem;
      transform: translateX(calc(calc(max(1280px, 100vw)/2 - 672px) * -1));
    `
  }

  ${
    media.desktop_small`
      margin-left: -5rem;
      transform: translateX(calc(calc(max(1280px, 100vw)/2 - 704px) * -1));
    `
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  :hover ~ .scroll-button-right {
    opacity: 1;
  }

  :hover ~ .scroll-button-left {
    opacity: 1;
  }
`

const Scroll = styled.div`
  display: inline-flex;
  transform: translateX(calc(max(1280px, 100vw) / 2 - 626px));


  ${media.desktop_medium_up`
      transform: translateX(calc(max(1280px, 100vw)/2 - 616px));
    `}

  
`

const ScrollButtonRight = styled.div`
  position: absolute;
  left:calc(100vw - 66px);
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #00000099;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: ${(props) => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  ${Icon} {
    filter: brightness(0) invert(1);
  }
  :hover {
    background: #000000AA;
    opacity: 1;
  }
  transform: translate(calc(calc(max(1024px, 100vw)/2 - 540px) * -1), -50%);

  ${media.desktop`
    transform: translate(-70px, -50%);
  `}  

  z-index: 5;
`

const ScrollButtonLeft = styled.div`
  position: absolute;
  left:0;
  top:50%;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background: #00000099;
  transform: translate(calc(calc(max(1024px, 100vw)/2 - 586px) * -1), -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: ${(props) => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  ${Icon} {
    transform: rotate(180deg);
    filter: brightness(0) invert(1);
  }
  :hover {
    background: #000000AA;
    opacity: 1;
  }
  ${media.desktop`
    transform: translate(0, -50%);
  `}
  ${
    media.desktop_medium_up`
      transform: translate(calc(calc(max(1024px, 100vw)/2 - 700px) * -1), -50%);
    `
  }
  z-index: 5;
`

const scrollRightFirstDiv = (ref) => {
  const currentLeft = ref.current.scrollLeft
  const nextChild = Array.from(ref.current.firstChild.children).find((v) => v.offsetLeft > currentLeft)
  if(nextChild) {
    ref.current.style.scrollBehavior = "smooth"
    ref.current.scrollLeft = nextChild.offsetLeft
    ref.current.style.scrollBehavior = "auto"
  }
}

const scrollLeftFirstDiv = (ref) => {
  ref.current.style.scrollBehavior = "smooth"
  const currentLeft = ref.current.scrollLeft
  const nextChild = Array.from(ref.current.firstChild.children).reverse().find((v) => v.offsetLeft < currentLeft)
  if(nextChild) {
    ref.current.style.scrollBehavior = "smooth"
    ref.current.scrollLeft = nextChild.offsetLeft
    ref.current.style.scrollBehavior = "auto"
  }
}

const ScrollComponent = ({ children }) => {

    const scrollRef = useRef(null)

    const widthRef = useRef(null);

    const [scrollOffsetHeight, setScrollOffsetHeight] = useState(null);

    const [showArrows, setShowArrows] = useState(false);

    const determineArrowVisability = () => {
      if (widthRef.current.offsetWidth >= window.innerWidth){
        setShowArrows(true)
      } else {
        setShowArrows(false)
      }
    }

    useEffect(() => {
      determineArrowVisability();
      setScrollOffsetHeight(scrollRef.current?.offsetHeight)
      window.addEventListener('resize', determineArrowVisability)
      return () => {
        window.removeEventListener('resize', determineArrowVisability)
      }
    }, [])

    useEffect(() => {
      
    }, [])

    return (
        <ScrollGridContainer height={scrollOffsetHeight}>
          <StyledScrollContainer ref={scrollRef}>
              <Scroll ref={widthRef}>
                      {children}
              </Scroll>
          </StyledScrollContainer>
          <ScrollButtonLeft show={showArrows} onClick={() => scrollLeftFirstDiv(scrollRef)} className={'scroll-button-left'}><Icon name={"arrow-right"}/></ScrollButtonLeft>
          <ScrollButtonRight show={showArrows} onClick={() => scrollRightFirstDiv(scrollRef)} className={'scroll-button-right'}><Icon name={"arrow-right"}/></ScrollButtonRight>
        </ScrollGridContainer>  
    )
}

ScrollComponent.propTypes = {
    children : PropTypes.node.isRequired
}

export default ScrollComponent;