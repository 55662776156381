import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import seedrandom from 'seedrandom'

import Link from '@atoms/link'
import Label from '@atoms/label'
import Text from '@particles/text'
import pmLogo from '@src/images/pm_logo.svg'
import Chart from '@molecules/chart'
import Icon from '@icons'
import Heading from '@particles/heading'

const PoweredByLabel = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: auto;
  height: 61px;
  background: #F6F8FE;
  border-radius: 2rem;
  cursor: pointer;
  .trial-start {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .pm_logo {
      height: 20px;
      width: 100px;
  }
`

const GetStartedLabel = styled(Label)`
    background: #4B57C5;
    color: #FFFFFF;
    font-size: 1rem;
    border-radius: 2rem;
    margin: 0;
    :hover {
        background: #21264F;
    }
`

const PoweredByCTA = () => {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              links {
                powermetrics
                startTrial
              }
            }
          }
        }
      `
    )
    return(
      <PoweredByLabel>
        <Link href={site.siteMetadata.links.startTrial} className={'trial-start'}>
          <div style={{width: '75px'}}>
            <Text color={"#2A2F3C"} size={0.625} margin={'R0.5'} lineHeight={'1.45'}>
                This Metric is powered by
            </Text>
          </div>
          <img className={'pm_logo'} src={pmLogo} alt="PowerMetrics by Klipfolio"/>
          <GetStartedLabel>Get started free</GetStartedLabel>
        </Link>
      </PoweredByLabel>
    )
}

PoweredByCTA.propTypes = {
    text : PropTypes.string,
    getStartedButton : PropTypes.any,
}


const VisualizationModalContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${({isOpen}) => isOpen ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    z-index: 1000;
`

const StyledVisualization = styled.div`
    position: relative;
    background: #FFFFFF;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    min-width: min(calc(100vw - 2rem), 500px);
    width: 866px;
    height: 614px;
    transition: width 0.4s linear;
    margin-bottom: 1rem;
`

const DarkBackground = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
`

const StyledChart = styled.div`
  box-sizing: border-box;
  background: #FFFFFF;
  border: solid 1px #D7DCEA;
  border-radius: 1rem;
  width: auto;
  padding: 1.5rem 2rem 2.5rem 1.5rem;
  position: relative;
  div[data-highcharts-chart] {
    overflow: visible !important;
    .highcharts-container {
      overflow: visible !important;
      svg {
        overflow: visible !important;
      }
    }
  }
`

const NumberChart = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: relative;
`

const NumberChartVs = styled.div`
  display: flex;
  align-items: center;
  ${Icon} {
    transform: ${props => props.goodTrendUp ? "rotate(-90deg)" : "rotate(90deg)"};
    filter: ${props => props.good ? "invert(53%) sepia(87%) saturate(320%) hue-rotate(102deg) brightness(91%) contrast(90%)" : "invert(18%) sepia(89%) saturate(3366%) hue-rotate(333deg) brightness(91%) contrast(95%);"};
  } 

  ${Text} {
    color: ${props => props.good ? "#34AD78" : "#E41B50"};
  }
`

const StyledChartText = styled.div`
  display: grid;
  margin-left: 0.75rem;
  margin-top: 1.5rem;
  grid-gap: 0.75rem;
`

const getChartName = (type) => {
    switch(type) {
      case "line":
        return "Line Chart"
      case "bar":
        return "Bar Chart"
      case "pie":
        return "Pie Chart"
      case "single":
        return "Single Value"
      default:
        return "Chart";
    }
  }

const generateNumberData = (min, max, seed) => {
    let random = seedrandom(seed)
    let val = random() * (max - min + 1) + min
    return val.toFixed(2)
  }
  const generateVsData = (value, seed) => {
    let random = seedrandom(seed + "vs")
    let min = 0.01
    let max = (value / 10) //10%
    let val = random() * (max - min + 1) + min
    let trend = random() > 0.25 ? true : false
    return { change: val.toFixed(2), trendUp: trend}
  }

const VisualizationModal = ({ isOpen, forwardRef, title, seed, data}) => {

    const checkIfHighChart = (data) => {
        return data.field_type !== "single"
    }

    const isHighChart = checkIfHighChart(data)

    const numberChart = !isHighChart && generateNumberData(parseFloat(data.field_y_axis_range[0]), parseFloat(data.field_y_axis_range[1]), seed)
    const numberVsChart = !isHighChart && generateVsData(numberChart, seed)

    return(
        <VisualizationModalContainer isOpen={isOpen}>
            <DarkBackground/>
            <StyledVisualization ref={forwardRef}>
                <StyledChart>
                    <Text margin={"B1"} style={{position:"relative"}}>{title}</Text>
                    {isHighChart ? 
                        <Chart data={data} seed={seed} height={'350'}/>
                        : 
                        <NumberChart>
                        <Text lineHeight={1.2} bold size={3}>{data.field_y_axis_prefix || ""}{numberChart}{data.field_y_axis_suffix || ""}</Text>
                        <NumberChartVs good={numberVsChart.trendUp} goodTrendUp={(data.field_positive_trend === "up" ? numberVsChart.trendUp : !numberVsChart.trendUp)}><Icon name={"arrow-right"}/> <Text margin={"L0.25"} size={1.5} lineHeight={1.2}>{numberVsChart.change}</Text></NumberChartVs>
                        <Text color={"200"} size={0.75}>vs previous period</Text>
                        </NumberChart>
                    }
                </StyledChart>
                <StyledChartText>
                    <Heading h4 bold>{data.field_label || getChartName(data.field_type)}</Heading>
                    <Text lineHeight={'1.75rem'} as={"span"}>{data.field_desc ? data.field_desc.replace("%metric%", title) : `Measuring ${title}`}</Text>
                </StyledChartText>
            </StyledVisualization>
            <PoweredByCTA/>
        </VisualizationModalContainer>
    )
}

VisualizationModal.propTypes = {
    isOpen : PropTypes.bool.isRequired,
    forwardRef : PropTypes.object.isRequired, 
    data : PropTypes.object,
    title : PropTypes.string,
    seed : PropTypes.string,
}

export default VisualizationModal;