import {useRef, useCallback, useEffect} from 'react';

export const useLongPress = (callback, {duration = 1}) => {
  const timer = useRef(null)
  const savedCallback = useRef(callback)
  const isPressed = useRef(false)
  const isLongPressedActive = useRef(false)

  const start = useCallback(() => {

    if(isPressed.current) {
      return
    }
    isPressed.current = true
    timer.current = setTimeout(() => {
      if(savedCallback.current) {
        savedCallback.current()
        isLongPressedActive.current = true
      }
    }, duration * 1000)
    
    
  }, [duration])

  const cancel = useCallback(() => {
    isPressed.current = false
    isLongPressedActive.current = false
    timer.current !== undefined && clearTimeout(timer.current)
  })

  useEffect(() => () => {
    timer.current !== undefined && clearTimeout(timer.current)
  },[])

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  return {
    onMouseDown: start,
    onMouseUp: cancel
  }
}
