/**
* Sub Nav
* @author Nicolas Venne
* 
* @copyright Klipfolio, All rights reserved
*/
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from '@breakpoints'

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
}

const SubNavContainer = styled.div`
  width: 100%;
  margin-left: -16px;
  ${media.desktop_medium_up`
    margin-left: calc((100vw - 1280px + 34px) / -2);
    width: 100vw;
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.5rem;
  position: fixed;
  height: 61px;
  top: 60px;
  background: white;
  z-index: 50;
  box-shadow: ${props => props.shadow ? '0px 4px 24px rgba(0, 0, 0, 0.2)' : ''};
`



const SubNavComponent = ({children, className, ...rest}) => {
 return <SubNavContainer {...rest}>
    <div className={className}>
      {children}
    </div>
 </SubNavContainer>
}

const SubNav = styled(SubNavComponent)`
  max-width: 1280px;
  padding: 0 1rem;
  display: flex;
  width: 100%;
`

SubNavComponent.propTypes = propTypes
export default SubNav