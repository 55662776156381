/**
 * Metric Template Component
 * @author Nicolas Venne
 *
 * @copyright Klipfolio, All rights reserved
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ServiceLogo from '@atoms/service-logo'
import Text from '@particles/text'
import Layout from '@organisms/layout'

const propTypes = {
  link: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  showLabel: PropTypes.bool
}

const StyledMetricTemplate = styled.a`
  display: flex;
  align-items: center;
  background: #75e1b2;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  border-radius: 32px;
  text-decoration: none;
`

/**
 * Adds noauth=signup on a given url
 *
 * @param {String} url A valid URL
 * @returns {String} new url with appened query string
 */
const appendNoAuthAndPageSourceToQuery = (_url, source = '') => {
  const url = new URL(_url)
  url.searchParams.set('noauth', 'signup')
  if (source) {
    return url.toString() + `&page_source=${source}`
  }
  return url.toString()
}

/**
 * Metric Template React Component
 *
 * @example
 * <MetricTemplate
 *  showLabel={false}
 *  link={"https://app.klipfolio.com/***"}
 *  service={"Facebook"}
 * />
 *
 * @param {String} link The direct link url to PowerMetrics
 * @param {String} service The name of the service this template belongs to
 */

const MetricTemplate = ({ link, service }) => {
  return (
    <StyledMetricTemplate
      id={`instant-metric-${service.toLowerCase().replace(/\s/g, '-')}`}
      href={appendNoAuthAndPageSourceToQuery(link)}
    >
      <ServiceLogo logo={service} xs bg={'white'} />
      <Layout.Column justify="start" style={{ flex: 1 }}>
        <Text as={'span'} color={'#014C33'} size={0.75} margin={'L0.5rem'}>
          {service}
        </Text>
      </Layout.Column>
    </StyledMetricTemplate>
  )
}

MetricTemplate.propTypes = propTypes

export default MetricTemplate
