/**
*  Faq
* @author Nicolas Venne
* 
* @copyright Klipfolio, All rights reserved
*/
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SourceHTML from '@particles/source-html'
import Heading from '@particles/heading'
import Icon from '@icons'
import Layout from '@organisms/layout'
import { useComponentVisible } from '@particles/hooks'

const propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  className: PropTypes.string
}

const StyledFaq = styled.div`
  
  width: 100%;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  margin: 0.5rem 0;
  
  ${Layout.Row} {

    background: #FFFFFF;
    padding: 1rem;
    margin-bottom: 0;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
  }

  ${Icon} {
    transition: transform 0.2s linear ;
    transform: ${props => props.show ? "rotate(-90deg)" : "rotate(90deg)"};
  }

  ${Heading} {
    font-weight: normal;
    line-height: 1.5;
    font-size: 1rem;
  }
`

const HtmlPadding = styled.div.attrs((props) => {
  let attrs = {}
  if (props.itemProp){
    attrs['itemprop'] = props.itemProp
  }
  return attrs
})`
  padding: 1rem;
`


const StyledBody = styled.div`
  max-height: ${props => props.show ? '400px' : '0px'};
  transition: max-height 0.3s linear ;
  overflow: scroll;
  cursor: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`


const Faq = ({title, body}) => {

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  return <StyledFaq ref={ref} show={isComponentVisible} onClick={() => setIsComponentVisible(!isComponentVisible)}>
    <Layout.Row margin={"B1"} justify={"space-between"} center>
      <Heading h4>{title}</Heading>
      <Icon name={"arrow-right"}/>
    </Layout.Row>
    <StyledBody show={isComponentVisible} onClick={(e) => e.stopPropagation()}>
      <HtmlPadding>
        <SourceHTML html={body} className={"metric-text"}></SourceHTML>
      </HtmlPadding>
    </StyledBody>
  </StyledFaq>
}


Faq.propTypes = propTypes
export default Faq