import React from 'react'
import Link from '@atoms/link'
import Text from '@particles/text'
import styled from 'styled-components'
import pmLogo from '@src/images/pm_logo.svg'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

const PoweredByLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
  cursor: pointer;
  border-radius: 2rem;
  padding-left: 0.5rem;
  margin-left: -0.5rem;
  transition: all 150ms ease;
  .trial-start {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .pm_logo {
      height: 20px;
      width: 100px;
  }
  :hover {
    background: #F6F8FE;
  }
`

const PoweredBy = ({ text = "Powered By" }) => {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              links {
                powermetrics
                startTrial
              }
            }
          }
        }
      `
    )
    return(
      <PoweredByLabel>
        <Link href={site.siteMetadata.links.startTrial} className={'trial-start'}>
          <Text color={"#2A2F3C"} size={'0.725'} margin={'R0.5'}>
            {text}
          </Text>
          <img className={'pm_logo'} src={pmLogo} alt="PowerMetrics by Klipfolio"/>
        </Link>
      </PoweredByLabel>
    )
  }

PoweredBy.propTypes = {
    text : PropTypes.string,
    getStartedButton : PropTypes.any,
}

export default PoweredBy;

