import styled from 'styled-components'

export const ToolkitButton = styled.button`
  width: 100%;
  background: linear-gradient(45deg, #fff, #efefef);
  border: none;
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 2rem;
  color: black;
  cursor: pointer;
  border: 2px solid #dfdfdf;
  box-shadow: 0 4px 6px rgba(0, 128, 93, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  letter-spacing: -0.4px;

  :hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }

  :focus {
    outline: none;
  }

  :hover::before {
    opacity: 1;
  }

  ::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 0.25);
    pointer-events: none;
  }

  ::before,
  ::after {
    mix-blend-mode: overlay;
  }
`
