/**
*  Chart
* @author Nicolas Venne
* 
* @copyright Klipfolio, All rights reserved
*/
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import seedrandom from 'seedrandom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const propTypes = {
  seed: PropTypes.string,
  expanded: PropTypes.bool,
  data: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number,PropTypes.string])
}

const countries = ['United States', "Canada", "United Kingdom", "France", "Ireland", "Mexico", "China", "Russia", "Japan", "India", "Brazil", "Germany", "Italy", "Spain", "Turkey", "Switzerland", "Poland", "Belgium", "Netherlands", "Sweden", "Norway", "Denmark", "Finland", "Portugal", "Ireland", "Austria", "Belgium", "Czech Republic", "Estonia", "Greece", "Hungary", "Latvia", "Lithuania", "Luxembourg", "Malta"]
const colors = ["#18AED8", "#00805D","#D24414", "#E41B50", "#4B57C5", "#5DD5F6", "#34AD78", "#F38338", "#FB6987", "#707EFA", "#1278A1", "#014C33", "#AC2E1F", "#B8143E", "#343C8D"]

const parseData = (data) => {
  if(data.y_axis_range_min) {
    data.y_axis_range_min = parseInt(data.y_axis_range_min)
  }
  if(data.y_axis_range_max) {
    data.y_axis_range_max = parseInt(data.y_axis_range_max)
  }
  return data
}
const generateXAxis = (type, length, seed, custom) => {
  if (type === 'months') {
    let date = new Date()
    date.setMonth(new Date().getUTCMonth() - (length - 1))
    return {
      categories: Array(length).fill(0).map(() => {
        let result = `${date.toLocaleDateString("en-US", {month: "short", year: "numeric"})}`
        date.setMonth(date.getUTCMonth() + 1)
        return result
      })    }
  } else if(type === 'years') {
    const today = new Date().getFullYear() - (length - 1)
    return {
      categories: Array(length).fill(today).map((v, i) => `${v + i}`)
    }
  } else if(type === 'days') {
    let date = new Date()
    date.setDate(new Date().getUTCDate() - (length - 1))
    return {
      categories: Array(length).fill(0).map(() => {
        let result = `${date.toLocaleDateString("en-US", {month: "short", day: "numeric"})}`
        date.setDate(date.getUTCDate() + 1)
        return result
      })
    }
  } else if(type === "country") {
    const random = seedrandom(seed)
    return {
      categories: countries.sort(() => random() - 0.5).slice(0, length)
    }
  } else if(type === "custom") {
    return {
      categories: custom
    }
  }
}

const shouldShowXAxisLine = (type) => {
  switch(type) {
    case 'pie':
      return false
    default:
      return true
  }
}

const canShowSpline = (type) => {
  switch(type) {
    case 'line':
      return true
    default:
      return false
  }
}

const selectType = (type) => {
  switch(type) {
    case 'line':
      return 'line'
    case 'pie':
      return 'pie'
    case 'bar':
      return 'column'
    default:
      return 'line'
  }
}

const isColorByPoint = type => {
  let colorByPoint = {
    colors
  }
  switch(type) {
    case 'bar':
      colorByPoint.colorByPoint = true
      return colorByPoint
    default:
      return {isColorByPoint: false}
  } 
}


const generateSeriesSettings = (type) => {
  return {
    type: selectType(type),
    ...isColorByPoint(type),
  }

}

const generateYAxis = type => {
  if(type === 'minutes') {
    return {
      type: 'datetime',
      tickInterval: 2 * 60 * 1000,
      startOnTick: true,
      dateTimeLabelFormats: {
        second: '%M:%S',
        minute: '%M:%S',
        hour: '%M:%S',
        day: '%M:%S',
        week: '%M:%S',
        month: '%M:%S',
        year: '%M:%S',
      }
    }
  } else if(type === 'seconds') {
    return {
      type: 'datetime',
      tickInterval: 2 * 1000,
      startOnTick: true,
      dateTimeLabelFormats: {
        millisecond: '%M:%S',
        second: '%M:%S',
        minute: '%M:%S',
        hour: '%M:%S',
        day: '%M:%S',
        week: '%M:%S',
        month: '%M:%S',
        year: '%M:%S',
      }
    }
  } else if(type === 'hours') {
    return {
      type: 'datetime',
      tickInterval: 2 * 60 * 60 * 1000,
      startOnTick: true,
      dateTimeLabelFormats: {
        second: '%H:%M',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%H:%M',
        week: '%H:%M',
        month: '%H:%M',
        year: '%H:%M',
      }
    }
  }
}

const generateData = (type, length, min, max, chart, seed) => {
  let data = []
  const random = seedrandom(seed)
  if(type === "minutes") {
    let minMinutes = min * 60 * 1000
    let maxMinutes = max * 60 * 1000
    for(let i = 0; i < length; i++) {
      data.push(Math.floor(random() * (maxMinutes - minMinutes + 1) + minMinutes))
    }
  } else if(type === "seconds") {
    let minSeconds = min * 1000
    let maxSeconds = max * 1000
    for(let i = 0; i < length; i++) {
      data.push(Math.floor(random() * (maxSeconds - minSeconds + 1) + minSeconds))
    }
  } else if(type === "hours") {
    let minHours = min * 60 * 60 * 1000
    let maxHours = max * 60 * 60 * 1000
    for(let i = 0; i < length; i++) {
      data.push(Math.floor(random() * (maxHours - minHours + 1) + minHours))
    }
  } else if(type === "normal") {
    for(let i = 0; i < length; i++) {
      data.push(Math.floor(random() * (max - min + 1) + min))
    }
  }
  
  return data
}
//Calculate the middle between the two points
const calculateSpline = lineData => {
  const spline = []
  spline.push(lineData[0])
  for(let i = 0; i < lineData.length - 1; i++) {
    spline.push((Math.abs((lineData[i + 1] - lineData[i])) / 2) + (lineData[i] > lineData[i + 1] ? lineData[i + 1] : lineData[i]))
    
  }
  return spline
  
}

const getDataLength = (custom, length = 12, type) => {
  switch(type) {
    case 'custom':
      return custom && custom.length
    default:
      return length
  }
}

const getYAxisTitle = (type, custom) => {
  if(custom) {
    return custom
  }
  switch(type) {
    case 'minutes':
      return 'Minutes'
    case 'seconds':
      return 'Seconds'
    case 'hours':
      return 'Hours'
    case 'normal':
      return 'Number'
    default:
      return ''
  }
}

const getXAxisTitle = (type, custom) => {
  if(custom) {
    return custom
  }
  switch(type) {
    case 'country':
      return 'Country'
    case 'months':
      return 'Month'
    case 'days':
      return 'Day'
    case 'years':
      return 'Year'
    default:
      return ""
  }
}

const getOptions = (data, chartData, seed, width, height) => {
  let options = {
    colors: ["#B0B8FC", "#4B57C5"], 
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        }
      },
      pie: {
        dataLabels: {
          enabled: false,
        },
        size: 166,
        center: ['50%', '60%'],
        colors
      }
    },
    series: [{
      ...generateSeriesSettings(data.type),
      data: chartData,
      
    }],
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: getXAxisTitle(data.x_axis, data.x_axis_label),
        enabled: false
      },
      lineWidth: shouldShowXAxisLine(data.type) ? 1 : 0,
      ...generateXAxis(data.x_axis, data.x_axis_length, seed, data.x_axis_custom)
    
    },
    legend: {
      enabled: false
    },
    title: {
      text: ""
    },
    yAxis: {
      title: {
        text: getYAxisTitle(data.y_axis, data.y_axis_label),
        enabled: false
      },
      labels: {
        allowOverlap: true
      },
      ...generateYAxis(data.y_axis)
    },
    tooltip: {
      formatter: function() {
        const time = new Date(this.y)
        const label = this.x || this.point.name;
        if(data.y_axis === 'minutes') {
          let minutes = 60 * time.getUTCHours() + time.getUTCMinutes()
          return `<b>${label}</b> : ${minutes}m ${time.getUTCSeconds()}s`
        } else if(data.y_axis === 'seconds') {
          let seconds = 60 * time.getUTCMinutes() + time.getUTCSeconds()
          return `<b>${label}</b> : ${seconds}.${time.getUTCMilliseconds()}s`
        } else if(data.y_axis === 'hours') {
          return `<b>${label}</b> : ${time.getUTCHours()}h ${time.getUTCMinutes()}m`
        } else if(data.y_axis === 'normal') {
          return `<b>${label}</b> : ${data.y_axis_prefix || ""}${this.y}${data.y_axis_suffix || ""}`
        }
      }
    },
    chart: {
      width: width,
      height: height
    },
    credits: {
      enabled: false
    },
  }
  if (canShowSpline(data.type) && data.trendline) {
    options.series.push({
      type: "spline",
      data: calculateSpline(chartData),
    })
  }
  return options
}

const generateChartData = (data, seed) => {
  let chartData = generateData(data.y_axis, getDataLength(data.x_axis_custom, data.x_axis_length, data.x_axis), data.y_axis_range_min, data.y_axis_range_max, data.type, seed)
  if(data.type === 'pie') {
    const xAxis = generateXAxis(data.x_axis, chartData.length, seed)
    chartData = chartData.map((y, i) => {
      return {
        y,
        name: xAxis.categories[i]
      }
    }) 
  }
  return chartData
}



const Chart = ({data, width, expanded, seed, height = null}) => {
  const [options, setOptions] = useState(getOptions({}, {}, seed, width, height))

  useEffect(() => {
    const newData = parseData(data)
    const newChartData = generateChartData(newData, seed);
    setOptions(getOptions(newData, newChartData, seed, width, height))
  }, [data, seed])

  useEffect(() => {
    setOptions((prevState) => {
      prevState.chart.width = width
      prevState.chart.height = height
      const newOptions = {...prevState}
      return newOptions
    })
  }, [width,height])

  useEffect(() => {
    setOptions((prevState) => {
      prevState.xAxis.labels.enabled = expanded
      prevState.yAxis.title.enabled = expanded
      prevState.xAxis.title.enabled = expanded
      const newOptions = {...prevState}
      return newOptions
    })
  }, [expanded])

  return <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
}


Chart.propTypes = propTypes
export default Chart