/**
 *  Visualization
 * @author Nicolas Venne
 *
 * @copyright Klipfolio, All rights reserved
 */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Chart from '@molecules/chart'
import Text from '@particles/text'
import Heading from '@particles/heading'
import Icon from '@icons'
import Image from '@atoms/image'
import { useLongPress } from '@hooks/use-long-press'
import seedrandom from 'seedrandom'
import PoweredBy from '@molecules/powered-by-label'
import { useMedia } from '@breakpoints'

const DEFAULT_CHART_SIZE = {
  desktop: {
    width: 370,
    height: 150
  },
  mobile: {
    width: 259,
    height: 105
  }
}

const propTypes = {
  data: PropTypes.object.isRequired,
  scroll: PropTypes.func,
  seed: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setIsComponentVisible: PropTypes.func,
  setData: PropTypes.func,
  setTitle: PropTypes.func,
  justViz: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number
}

const StyledBackdropImage = styled(Image)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 1rem;
`

const StyledImageCover = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  border-radius: 1rem;
`

const StyledVisualization = styled.div`
  ${
    '' /* background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05); */
  }
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width + 50}px;
  margin-right: ${(props) => (props.justViz ? '0' : '1.5rem')};
  transition: width 0.4s linear;
  ${'' /* position: ${props => (props.expanded ? 'fixed' : 'relative')}; */}
  :last-child {
    margin-right: 0;
  }
`

const StyledChart = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  width: ${(props) => (props.width ? `calc(${props.width}px + 3rem)` : 'auto')};
  height: ${(props) =>
    props.height
      ? `calc(${props.height}px + ${props.justViz ? '2rem' : '9.5rem'})`
      : 'auto'};
  padding: 1.5rem 2rem 1.5rem ${(props) => (props.justViz ? '1rem' : '1.5rem')};
  position: relative;
  div[data-highcharts-chart] {
    overflow: visible !important;
    .highcharts-container {
      overflow: visible !important;
      svg {
        overflow: visible !important;
      }
    }
  }

  .highcharts-root {
    position: relative;
    padding-top: 0.5rem;
    outline: ${({ justViz }) => (justViz ? '' : '1px solid #D7DCEA')};
    border-radius: 0.5rem;
  }
`

const PoweredByContainer = styled.div`
  margin-top: 1.5rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: ${(props) =>
    props.width ? props.width : DEFAULT_CHART_SIZE.desktop.width}px;
`

const ExpandButton = styled.button`
  position: relative;
  display: flex;
  padding: 0.375rem 0.75rem;
  align-items: center;
  background: #e8fde2;
  border: 0;
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;
  transition: all 150ms ease;
`

const StyledIcon = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background: #34ad78;
`

const StyledChartText = styled.div`
  display: grid;
  margin-left: 0.75rem;
  margin-top: 1.5rem;
  grid-gap: 1rem;
`

const NumberChart = styled.div`
  width: ${(props) => props.width || DEFAULT_CHART_SIZE.desktop.width}px;
  height: ${(props) => props.height || DEFAULT_CHART_SIZE.desktop.height}px;
  outline: ${(props) => (props.justViz ? '' : '1px solid #D7DCEA')};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: relative;
`

const NumberChartVs = styled.div`
  display: flex;
  align-items: center;
  ${Icon} {
    transform: ${(props) =>
      props.goodTrendUp ? 'rotate(-90deg)' : 'rotate(90deg)'};
    filter: ${(props) =>
      props.good
        ? 'invert(53%) sepia(87%) saturate(320%) hue-rotate(102deg) brightness(91%) contrast(90%)'
        : 'invert(18%) sepia(89%) saturate(3366%) hue-rotate(333deg) brightness(91%) contrast(95%);'};
  }

  ${Text} {
    color: ${(props) => (props.good ? '#34AD78' : '#E41B50')};
  }
`

const TextBox = styled.div`
  width: ${(props) => (props.width ? props.width : '370')}px;
`
const getChartName = (type) => {
  switch (type) {
    case 'line':
      return 'Line Chart'
    case 'bar':
      return 'Bar Chart'
    case 'pie':
      return 'Pie Chart'
    case 'single':
      return 'Single Value'
    default:
      return 'Chart'
  }
}

const generateNumberData = (min, max, seed, decimals = 2) => {
  let random = seedrandom(seed)
  let val = random() * (max - min + 1) + min
  return decimals === 0 ? Math.round(val) : val.toFixed(decimals)
}
const generateVsData = (value, seed, decimals = 2) => {
  let random = seedrandom(seed + 'vs')
  let min = 0.01
  let max = value / 10 //10%
  let val = random() * (max - min + 1) + min
  let trend = random() > 0.25 ? true : false
  return {
    change: decimals === 0 ? Math.ceil(val) : val.toFixed(2),
    trendUp: trend
  }
}

const Visualization = ({
  title,
  data,
  seed,
  setIsComponentVisible,
  setData,
  setTitle,
  justViz,
  width,
  height
}) => {
  const [chartWidth, setChartWidth] = useState(
    width || DEFAULT_CHART_SIZE.desktop.width
  )
  const [chartHeight, setChartHeight] = useState(
    height || DEFAULT_CHART_SIZE.desktop.height
  )

  const checkIfHighChart = (data) => {
    return data.type !== 'single'
  }

  const isMobile = useMedia('phablet')

  const isHighChart = checkIfHighChart(data)

  const chartRef = useRef(null)

  const [pictureMode, setPictureMode] = useState(false)
  const bind = useLongPress(
    () => {
      setPictureMode(!pictureMode)
    },
    { duration: 5 }
  )

  const numberChart =
    !isHighChart &&
    generateNumberData(
      parseFloat(data.y_axis_range_min),
      parseFloat(data.y_axis_range_max),
      seed,
      data.y_axis_decimals
    )
  const numberVsChart =
    !isHighChart &&
    generateVsData(numberChart, seed, data.y_axis_decimals === 0 ? 0 : 2)

  const onExpandClick = (title) => {
    setIsComponentVisible(true)
    setData()
    setTitle(title)
  }

  useEffect(() => {
    if (isMobile && justViz) {
      setChartWidth(DEFAULT_CHART_SIZE.mobile.width)
      setChartHeight(DEFAULT_CHART_SIZE.mobile.height)
    } else {
      setChartWidth(width || DEFAULT_CHART_SIZE.desktop.width)
      setChartHeight(height || DEFAULT_CHART_SIZE.desktop.height)
    }
  }, [isMobile])

  const image = data.seo_image?.url

  return (
    <StyledVisualization
      justViz={justViz}
      width={chartWidth}
      height={chartHeight}
      ref={chartRef}
      {...bind}
    >
      <StyledChart width={chartWidth} height={chartHeight} justViz={justViz}>
        {image && <StyledBackdropImage src={image} />}
        {image && <StyledImageCover />}
        {justViz ? undefined : (
          <Text
            margin={'B1'}
            style={{
              position: 'relative',
              visibility: pictureMode ? 'hidden' : 'visible'
            }}
          >
            {title}
          </Text>
        )}
        {isHighChart ? (
          <>
            <Chart
              data={data}
              height={chartHeight}
              width={chartWidth}
              seed={seed}
            />
            {justViz ? undefined : (
              <PoweredByContainer expandable>
                <PoweredBy />
                <ExpandButton onClick={() => onExpandClick(title)}>
                  <Text
                    color={'#34AD78'}
                    as={'span'}
                    margin={'R0.5'}
                    size={'0.725'}
                  >
                    Expand View
                  </Text>
                  <StyledIcon>
                    <Icon
                      size={0.75}
                      name={'expand'}
                      style={{ filter: 'invert(100%)' }}
                    />
                  </StyledIcon>
                </ExpandButton>
              </PoweredByContainer>
            )}
          </>
        ) : (
          <>
            <NumberChart
              width={chartWidth}
              height={chartHeight}
              justViz={justViz}
            >
              <Text lineHeight={1.2} bold size={justViz ? 4.5 : 3}>
                {data.y_axis_prefix || ''}
                {numberChart}
                {data.y_axis_suffix || ''}
              </Text>
              <NumberChartVs
                good={numberVsChart.trendUp}
                goodTrendUp={
                  data.positive_trend === 'up'
                    ? numberVsChart.trendUp
                    : !numberVsChart.trendUp
                }
              >
                <Icon size={justViz ? 1.5 : 1} name={'arrow-right'} />{' '}
                <Text
                  margin={'L0.25'}
                  size={justViz ? '2.5' : '1.5'}
                  lineHeight={1.2}
                >
                  {numberVsChart.change}
                </Text>
              </NumberChartVs>
              <Text color={'200'} size={justViz ? '1' : '0.75'}>
                vs previous period
              </Text>
            </NumberChart>
            {justViz ? undefined : (
              <PoweredByContainer>
                <PoweredBy />
              </PoweredByContainer>
            )}
          </>
        )}
      </StyledChart>
      {justViz ? undefined : (
        <StyledChartText>
          <Heading h4 bold>
            {data.label || getChartName(data.type)}
          </Heading>
          <TextBox width={chartWidth}>
            <Text lineHeight={'1.75rem'} as={'span'}>
              {data.description
                ? data.description.replace('%metric%', title)
                : `Measuring ${title}`}
            </Text>
          </TextBox>
        </StyledChartText>
      )}
    </StyledVisualization>
  )
}

Visualization.propTypes = propTypes
export default Visualization
