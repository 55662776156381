import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from '@molecules/modal'
import TrackMetric from '@organisms/track-metric'
import MetricTemplate from '@molecules/metric-template'
import { CloseButton } from '@atoms/close-button'
import { ToolkitButton } from './styles'

const Controls = styled.div`
  position: absolute;
  display: flex;
  top: 1rem;
  background: #75e1b2;
  right: 1rem;
  z-index: 1;
  padding: 0.75rem 0.5rem;
  border-radius: 100%;
  box-shadow: 0 4px 6px rgba(0, 128, 93, 0.1);
`

const CtaButton = ({ metric, templates, location }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  return (
    <>
      <Modal style={{ maxWidth: '900px' }} showButton={false} open={open}>
        <Controls>
          <CloseButton
            opacity={0.6}
            hoverOpacity={1.0}
            fill="#014C33"
            toClose={toggleOpen}
          />
        </Controls>
        <TrackMetric globalMetric={metric}>
          {templates &&
            templates.map((template, i) => (
              <MetricTemplate
                key={`template-${i}`}
                showLabel={templates.length <= 2}
                service={template.service.name}
                link={template.url}
                pageSource={location?.pathname}
              />
            ))}
        </TrackMetric>
      </Modal>
      <ToolkitButton onClick={toggleOpen}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 48 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.6948 37.3672L39.6008 16.3772L34.4118 2.9852C33.4958 0.5902 30.8118 -0.6088 28.4168 0.3072C27.1838 0.7792 26.2108 1.7532 25.7388 2.9852L19.7938 18.3592L19.7778 18.3162L17.9978 13.5142C17.0488 11.1272 14.3428 9.9622 11.9568 10.9112C10.7418 11.3952 9.7868 12.3672 9.3248 13.5902L0.298798 37.3762C-0.0992025 38.4192 -0.0992025 39.5732 0.298798 40.6172C0.434798 40.9882 0.620798 41.3382 0.852798 41.6582C1.7218 42.9152 3.1528 43.6652 4.6818 43.6642H43.3538C45.9178 43.6672 47.9978 41.5902 47.9998 39.0272C48.0008 38.4602 47.8968 37.8972 47.6948 37.3672Z"
            fill="#4B57C5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.3999 43.6646H4.6819C3.1529 43.6646 1.7219 42.9146 0.852897 41.6576C0.620897 41.3376 0.434897 40.9876 0.298897 40.6166L22.9679 26.6626L29.3999 43.6646Z"
            fill="#404BA8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.1909 41.6412C46.3239 42.9072 44.8889 43.6642 43.3539 43.6642H29.3999L22.9679 26.6622L39.6009 16.3772L47.6949 37.3672C48.2339 38.7882 48.0459 40.3842 47.1909 41.6412Z"
            fill="#4B57C5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.6011 16.377L22.9681 26.663L19.7921 18.362L25.7391 2.985C26.6551 0.59 29.3391 -0.608 31.7341 0.308C32.9671 0.779 33.9411 1.753 34.4121 2.985L39.6011 16.377Z"
            fill="#6070FC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9683 26.6627L0.299303 40.6167C-0.0996966 39.5737 -0.0996966 38.4197 0.299303 37.3757L9.3243 13.5897C10.2323 11.1867 12.9163 9.9747 15.3203 10.8827C16.5423 11.3447 17.5143 12.2997 17.9983 13.5147L19.7783 18.3167L22.9683 26.6627Z"
            fill="#4B57C5"
          />
        </svg>
        Visualize In PowerMetrics
      </ToolkitButton>
    </>
  )
}

CtaButton.propTypes = {
  metric: PropTypes.object,
  templates: PropTypes.array,
  location: PropTypes.any
}

export default CtaButton
