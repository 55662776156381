import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

import Link from "@atoms/link";
import Text from "@particles/text";
import arrow from "../../../assets/icons/icon-right-arrow.svg"

const StyledOL = styled.ol`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    ${Text} {
        display: inline;
    }


    li:not(:last-child):after {
        content: "";
        display: inline-block;
        margin: 0 1rem;
        background-image: url(${arrow});
        background-size: 16px 16px;
        height: 16px;
        width: 16px;
    }
}
`

const Breadcrumbs = ({crumbs}) => {
    return(
        <StyledOL itemscope itemtype="https://schema.org/BreadcrumbList">
            {crumbs.map((crumb, i) => {
                return(
                    <li itemScope itemType="http://schema.org/ListItem" key={`crumb-${i+1}`}>
                        {crumb.active ?
                            <Link itemProp="item" to={crumb.to}>
                                <span style={{fontWeight: '500'}} itemProp="name">{crumb.text}</span>
                                <meta itemProp="position" content={i+1} />
                            </Link>
                            :
                            <Text itemProp="item" bold>
                                <span itemProp="name">{crumb.text}</span>
                                <meta itemProp="position" content={i+1} />
                            </Text>
                        }
                    </li>
                )
            })}
        </StyledOL>
    )
}

Breadcrumbs.propTypes = {
    crumbs : PropTypes.array.isRequired,
}



export default Breadcrumbs;
