/**
 *  Youtube
 * @author Nicolas Venne
 *
 * @copyright Klipfolio, All rights reserved
 */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const YoutubeComponent = ({ src, className }) => {
  const ref = useRef()
  const [height, setHeight] = useState('0')

  useEffect(() => {
    const resizeListener = window.addEventListener('resize', () => {
      calculateHeight()
    })

    const calculateHeight = () => {
      if (ref.current.clientWidth) {
        setHeight(`${(900 / 1600) * ref.current.clientWidth}px`)
      }
    }

    calculateHeight()

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return (
    <iframe
      ref={ref}
      className={className}
      src={src}
      style={{ height }}
      frameBorder="0"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}

YoutubeComponent.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string
}

const Youtube = styled(YoutubeComponent)`
  width: 100%;

  margin-bottom: 1.5rem;

  :last-child {
    margin-bottom: 0;
  }
`

export default Youtube
