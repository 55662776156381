// TWEAKED FROM https://github.com/alexcrist/json-to-pretty-yaml

var typeOf = require('remedial').typeOf
var trimWhitespace = require('remove-trailing-spaces')

export function yamlify(data) {
  var handlers,
    indentLevel = ''

  handlers = {
    undefined: function() {
      return 'null'
    },
    null: function() {
      return 'null'
    },
    number: function(x) {
      return x
    },
    boolean: function(x) {
      return x ? 'true' : 'false'
    },
    string: function(x) {
      return x
    },
    array: function(x) {
      var output = ''

      if (0 === x.length) {
        output += '[]'
        return output
      }

      indentLevel = indentLevel.replace(/$/, '  ')
      x.forEach(function(y) {
        var handler = handlers[typeOf(y)]

        if (!handler) {
          throw new Error('what the crap: ' + typeOf(y))
        }

        output += '\n' + indentLevel + '- ' + handler(y, true)
      })
      indentLevel = indentLevel.replace(/ {2}/, '')

      return output
    },
    object: function(x, inArray, rootNode) {
      var output = ''

      if (0 === Object.keys(x).length) {
        output += '{}'
        return output
      }

      if (!rootNode) {
        indentLevel = indentLevel.replace(/$/, '  ')
      }

      Object.keys(x).forEach(function(k, i) {
        var val = x[k],
          handler = handlers[typeOf(val)]

        if ('undefined' === typeof val) {
          return
        }

        if (!handler) {
          throw new Error('what the crap: ' + typeOf(val))
        }

        if (!(inArray && i === 0)) {
          output += '\n' + indentLevel
        }

        output += k + ': ' + handler(val)
      })
      indentLevel = indentLevel.replace(/ {2}/, '')

      return output
    },
    function: function() {
      return '[object Function]'
    }
  }

  return trimWhitespace(handlers[typeOf(data)](data, true, true) + '\n')
}
