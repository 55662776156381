import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@particles/text'
import { getDbtShell } from '@utils/get-dbt-shell'
import { CopyButton } from '@atoms/copy-button'
import { CloseButton } from '@atoms/close-button'
import Modal from '@molecules/modal'
import { ToolkitButton } from './styles'

const ChatText = styled(Text)`
  font-size: 1rem;
  color: white;
  a {
    margin-bottom: 2rem;
  }
  table {
    color: #2a2f3c;
  }
`
const ChatWrapper = styled.div`
  background: #2a2f3c;
  border-radius: 0.5rem;
  max-width: 100%;
  position: relative;
`

const ChatInner = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  padding: 2rem;
`

const Controls = styled.div`
  position: absolute;
  display: flex;
  gap: 0.5rem;
  top: 1rem;
  right: 2rem;
`

const DbtButton = ({ metric, formula }) => {
  const [response, setResponse] = useState('')

  const handleSubmit = useCallback(() => {
    const shell = getDbtShell(metric, formula)
    setResponse(shell)
  }, [getDbtShell, metric, formula, setResponse])

  const closeModal = useCallback(() => {
    setResponse('')
  }, [setResponse])

  return (
    <>
      <Modal showButton={false} open={response}>
        <ChatWrapper>
          <Controls>
            <CopyButton text={response} />
            <CloseButton toClose={closeModal} />
          </Controls>
          <ChatInner>
            <ChatText>{response}</ChatText>
          </ChatInner>
        </ChatWrapper>
      </Modal>
      <ToolkitButton onClick={handleSubmit}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7_30)">
            <path
              d="M28.7251 1.24774C29.4304 1.92586 29.8915 2.82098 30 3.79747C30 4.20433 29.8915 4.4756 29.6474 4.99097C29.4033 5.50633 26.3924 10.7143 25.4973 12.1519C24.9819 12.9928 24.7107 13.9964 24.7107 14.9729C24.7107 15.9765 24.9819 16.953 25.4973 17.7939C26.3924 19.2315 29.4033 24.4665 29.6474 24.9819C29.8915 25.4973 30 25.7414 30 26.1483C29.8915 27.1248 29.4575 28.0199 28.7523 28.6709C28.0741 29.3761 27.179 29.8373 26.2297 29.9186C25.8228 29.9186 25.5515 29.8101 25.0633 29.566C24.575 29.3219 19.2586 26.3924 17.821 25.4973C17.7125 25.443 17.604 25.3617 17.4684 25.3074L10.3617 21.1031C10.5244 22.4593 11.1212 23.7613 12.0976 24.7107C12.2875 24.9005 12.4774 25.0633 12.6944 25.226C12.5316 25.3074 12.3418 25.3888 12.179 25.4973C10.7414 26.3924 5.50633 29.4033 4.99097 29.6474C4.4756 29.8915 4.23147 30 3.79747 30C2.82098 29.8915 1.92586 29.4575 1.27486 28.7523C0.56962 28.0741 0.108499 27.179 0 26.2025C0.0271248 25.7957 0.135624 25.3888 0.352623 25.0362C0.596747 24.5208 3.6076 19.2857 4.5027 17.8481C5.0181 17.0072 5.28933 16.0307 5.28933 15.0271C5.28933 14.0235 5.0181 13.047 4.5027 12.2061C3.6076 10.7143 0.56962 5.4792 0.352623 4.96383C0.135624 4.6112 0.0271248 4.20433 0 3.79747C0.108499 2.82098 0.542497 1.92586 1.24774 1.24774C1.92586 0.542497 2.82098 0.108499 3.79747 0C4.20433 0.0271248 4.6112 0.135624 4.99097 0.352623C5.42497 0.542497 9.24953 2.76673 11.2839 3.96023L11.745 4.23147C11.9078 4.33997 12.0434 4.42133 12.1519 4.4756L12.3689 4.6112L19.6112 8.89693C19.4485 7.26943 18.6076 5.77757 17.3056 4.77397C17.4684 4.6926 17.6582 4.6112 17.821 4.5027C19.2586 3.6076 24.4937 0.56962 25.009 0.352623C25.3617 0.135624 25.7685 0.0271248 26.2025 0C27.1519 0.108499 28.047 0.542497 28.7251 1.24774ZM15.3797 16.9259L16.9259 15.3797C17.1429 15.1627 17.1429 14.8373 16.9259 14.6203L15.3797 13.0741C15.1627 12.8571 14.8373 12.8571 14.6203 13.0741L13.0741 14.6203C12.8571 14.8373 12.8571 15.1627 13.0741 15.3797L14.6203 16.9259C14.8101 17.1157 15.1627 17.1157 15.3797 16.9259Z"
              fill="#FF694A"
            />
          </g>
          <defs>
            <clipPath id="clip0_7_30">
              <rect width="30" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
        dbt Metric Definition
      </ToolkitButton>
    </>
  )
}

DbtButton.propTypes = {
  metric: PropTypes.object,
  formula: PropTypes.object,
  dimensions: PropTypes.array
}

export default DbtButton
