import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled.button`
  background: none;
  border: none;
  svg {
    transition: 0.2s opacity;
    opacity: ${(props) => props.opacity};
    fill: ${(props) => props.fill ?? `white`};
  }
  &:hover {
    cursor: pointer;
    svg {
      opacity: ${(props) => props.hoverOpacity};
      fill: ${(props) => props.fill ?? `white`};
    }
  }
`

export const CloseButton = ({
  toClose,
  fill,
  opacity = 0.2,
  hoverOpacity = 0.4,
  ...rest
}) => {
  return (
    <StyledButton
      onClick={toClose}
      fill={fill}
      opacity={opacity}
      hoverOpacity={hoverOpacity}
      {...rest}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 21 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9873 10.6105L20.1186 17.7401C20.7756 18.3972 20.7756 19.4607 20.1186 20.1177C19.4615 20.7747 18.398 20.7747 17.741 20.1177L10.6113 12.9864L3.48166 20.1177C2.82464 20.7747 1.76112 20.7747 1.10409 20.1177C0.447073 19.4607 0.447073 18.3972 1.10409 17.7401L8.23538 10.6105L1.10409 3.4808C0.447073 2.82378 0.447073 1.76026 1.10409 1.10324C1.76112 0.446218 2.82464 0.446218 3.48166 1.10324L10.6113 8.23452L17.741 1.10324C18.398 0.446218 19.4615 0.446218 20.1186 1.10324C20.7756 1.76026 20.7756 2.82378 20.1186 3.4808L12.9873 10.6105Z"
          fill="inherit"
        />
      </svg>
    </StyledButton>
  )
}

CloseButton.propTypes = {
  toClose: PropTypes.func,
  fill: PropTypes.string,
  opacity: PropTypes.number,
  hoverOpacity: PropTypes.number
}
