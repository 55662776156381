import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const EmbedContainer = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
  height: 600px;
  margin-top: 1rem;
  background: #CCCCCC;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
`

const LiveEmbed = ({ liveEmbed }) => {
  if (liveEmbed) {
    return (
      <EmbedContainer>
        <embed-client token='https://www.metrichq.org/api/sign' id={liveEmbed.name} metrics={liveEmbed.metrics.join(',')} theme='light' readonly={liveEmbed.readonly} showpropspanel={liveEmbed.showpropspanel}>
          {liveEmbed.vis_spec}
        </embed-client>
      </EmbedContainer>
    )
  }
  return null
}

LiveEmbed.propTypes = {
  liveEmbed: PropTypes.object
}

export default LiveEmbed
