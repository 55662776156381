import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from '@atoms/label'
import SubNav from "@molecules/sub-nav";
import scrollIntoView from 'scroll-into-view-if-needed'


const propTypes = {
  className: PropTypes.string
}

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading) => {
    const { innerText: title, id, dataset: {toc: toc} } = heading;

    if(toc === "true" || toc === "") {
      nestedHeadings.push({ id, title});
    } else if(toc) {
      nestedHeadings.push({ id, title: toc});
    }
  });

  return nestedHeadings;
};

const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = useState([]);

  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("h2, h3")
    );

    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = useRef({});
  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        if(headingElement.target.dataset.toc)
          map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
      } else if(visibleHeadings.length === 0) {
        setActiveId(null);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "-80px 0px -40% 0px"
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

const StyledSubNav = styled(SubNav)`
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    flex-direction: row;
`

const StyledLabel = styled(Label)`
    color: ${(props) =>
      props.active
          ? props.theme.palette.primary[400]
          : "#485066"};
    background: none;
    border: 1px solid #D7DCEA;
    border-radius: 2rem;
    font-size: 0.75rem;
    margin: 0 0.5rem;
    padding: 0.75rem 1rem;
    line-height: 14px;
    white-space: nowrap;
    :first-child {
      margin-left: 0;
    }
    
`

const TOCSubNav = ({className}) => {
  const [activeId, setActiveId] = useState();
  useIntersectionObserver(setActiveId);
  const { nestedHeadings } = useHeadingsData();
  const tocHeadings = useRef({})

  useEffect(() => {
    const element = tocHeadings.current[activeId];
    if(element) {
      scrollIntoView(element, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "nearest"
      });
    }
  }, [activeId])

  return (
    <StyledSubNav shadow className={className}>
      {nestedHeadings.map((heading) => {
        return(
            <StyledLabel
                key={`heading-${heading.id}`} 
                active={activeId === heading.id} 
                fowardRef={(element) => {
                  tocHeadings.current[heading.id] = element;
                }}
                onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${heading.id}`).scrollIntoView({behavior: "smooth"});
                }}>
                {heading.title}
            </StyledLabel>
        )
        }
      
        )}
    </StyledSubNav>
  )
}


TOCSubNav.propTypes = propTypes
export default styled(TOCSubNav)``